* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
}

.container {
  width: 100%;
  height: 100vh;
  /* background: #e3f9ff; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.calculator {
  max-width: 365px;
  background: #3a4452;
  padding: 20px;
  border-radius: 10px;
}
.calculator form input,
.calculator form button {
  border: 0;
  outline: 0;
  width: 60px;
  height: 60px;
  border-radius: 10px;
  box-shadow: -8px -8px 15px rgba(255, 255, 255, 0.1), 5px 5px 15px rgba(0, 0, 0, 0.2);
  background: transparent;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
  margin: 10px;
}

form .display {
  margin: 10px 0;
  height: 100px;
  overflow: hidden;
}
form .display p {
  text-align: right;
  color: #fff;
  font-size: 45px;
  box-shadow: none;
  height: 50px;
}

form button.double {
  width: 145px;
}

form button.operator {
  color: #33ffd8;
}
